import * as Yup from "yup";
import { Col, Row, Button } from "reactstrap";
import React, { useState } from "react";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import { PERIOD } from "./regex";
import Loader from "../../../components/Loader/Loader";
import ModalTableVer from "../../../components/Modal/ModalTableVer";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import {
  TextInput,
  SelectTypeHeadSingle,
} from "../../../components/GenericInputsFormik/index";
import { GetPermissions } from "../../../libs/permissions/getPermissions";

import axios from "axios";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import { peticionEncript } from "../../../helpers/peticionesEncripted";
import Datatable from "../../../components/Datatable/Datatable";
import FullFormLoader from "../../../components/Loader/FullFormLoader";
import { doRequestSaveRes } from "../../../helpers/requests";

const FormCalculo = ({ crear, data }) => {
  GetPermissions();

  const defaultForm = {
    period: 0,
    year: new Date(),
    idStaff: "",
    staffFullName: "",
    mensual: "1",
  };
  const filtroModal = [true, true, true, true, true];
  const { id } = useSelector((state) => state.userInfo);
  const date = new Date().getFullYear();

  const parametrosModal = {
    action: "datatable",
    table:
      "staff STF INNER JOIN employtypes AS EMT ON STF.keyEmployType = EMT.keyEmployType INNER JOIN jobstaff AS JBS ON JBS.idStaff = STF.idStaff INNER JOIN areas AS AR ON AR.idArea = JBS.idArea INNER JOIN jobs AS J ON JBS.keyJob = J.keyJob",
    rows: "STF.idStaff,STF.staffNumber,CONCAT(STF.name,' ',STF.pName,' ',STF.mName) as fullName,AR.name,J.nameJob",
    conditions: `JBS.valid = 1 AND STF.enabled = 1 AND JBS.enabled = 1 AND NOT STF.idStaff = ${id}`,
    page: 0,
    records: 5,
    search: "",
    order: "",
  };

  const cabecerasModal = [
    "#",
    "Núm. Empleado",
    "Nombre",
    "Unidad de trabajo",
    "Puesto",
    "Seleccionar",
  ];

  const [form, setForm] = useState(defaultForm);
  const [modal, setModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalTabla, setModalTabla] = useState(false);
  const [resErrors, setResErrors] = useState(undefined);

  const OnChangeSelect = (event) => {
    const { name, value } = event.target;
    setFieldValue(name, value);
  };

  const FormSchema = Yup.object().shape({
    period: Yup.number()
      .moreThan(0, "Favor de seleccionar un periodo")
      .required("Favor de seleccionar un periodo"),

    year: Yup.string()
      .min(4, "El Año debe estar constituido por 4 digitos")
      .required("Favor de ingresar el Año de calculo"),
  });

  const selectStaff = (el) => {
    setFieldValue("idStaff", el[0]);
    setFieldValue("staffFullName", `${el[2]} - No. Empleado: ${el[1]}`);
    setModalTabla(false);
  };

  const onChangeDate = (e) => {
    setFieldValue(e.name, e.value);
  };

  const sendInfo = async () => {
    if (Object.keys(errors).length === 0) {
      setIsLoading(true);
      setResErrors(undefined);
      const result = await createCalculo(values);
      if (result === false) {
        setIsLoading(false);
      } else {
        setIsLoading(false);
        resetForm();
      }
    }
  };

  function setMensual() {
    if (values.mensual === "1") {
      setFieldValue("mensual", "2");
    } else {
      setFieldValue("mensual", "1");
    }
  }

  const {
    handleSubmit,
    values,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: form,
    onSubmit: () => setModal(true),
    validationSchema: FormSchema,
  });

  const createCalculo = async (data) => {
    if (data) {
      const params = {
        action: "ejecutaCalculo",
        rows: {
          period: Number(data.period),
          year: Number(data.year.getFullYear()),
          idStaff: data.idStaff,
          mensual: data.mensual,
        },
      };
      const finalData = peticionEncript(params);
      
      const res = await doRequestSaveRes("app/facades/payslips/payslipF.php", params, true, true, true);
      if(res.status === "success"){
        toast(<Notification type="agrega_exito" backMessage={res.message} withIcon/>, {closeButton: false});
      }else if(res){
        toast(<Notification type="error" backMessage={res.message} withIcon/>, {closeButton: false});
        setResErrors(res);
      }
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit} className="mt-4" noValidate>
          <ModalConfirmation
            modalTitle={data ? "Editar" : "Crear"}
            modal={modal}
            setModal={setModal}
            crear={sendInfo}
            isEdit={false}
            values={values}
          >
            {data ? (
              <div className="d-flex justify-content-center">
                <h6>¿Está seguro de editar el registro?</h6>
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                <h6>¿Desea continuar con el registro?</h6>
              </div>
            )}
          </ModalConfirmation>
          <div className="d-flex align-items-center">
            <p className="mr-2">Calculo del ISR: </p>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                height: "fit-content",
              }}
            >
              <div
                className="toggle-container mt-0 p-0"
                style={{ width: "120px" }}
                onClick={setMensual}
              >
                <div
                  className={`toggle-btn ${
                    values.mensual !== "1" ? "disable" : ""
                  }`}
                  style={{ width: "auto" }}
                >
                  {values.mensual === "1" ? "Mensual" : "Quincenal"}
                </div>
              </div>
            </div>
          </div>

          <Row className="mt-2">
            <Col>
              <SelectTypeHeadSingle
                label="Periodo"
                isRequired={true}
                inputName="period"
                optionsArray={PERIOD}
                defaultOption="Seleccione un periodo"
                onChangeMethod={OnChangeSelect}
                onBlur={handleBlur}
                isDisabled={false}
                value={values.period}
                touched={touched.period}
                errors={errors.period}
                optionValue="value"
                optionName="label"
              />
            </Col>

            <Col>
              <label htmlFor={values.year}>
                {" "}
                Año <span className="text-danger"> * </span>{" "}
              </label>
              <DatePicker
                // minDate={new Date(`${date}`)}
                selected={values.year}
                onChange={(date) => onChangeDate({ name: "year", value: date })}
                showYearPicker
                dateFormat="yyyy"
                yearItemNumber={6}
                className={
                  "form-control" +
                  (errors.year && touched.year ? " is-invalid" : "")
                }
              />

              {touched.year && errors.year && (
                <span style={{ color: "red" }}>{errors.year}</span>
              )}
            </Col>
          </Row>

          <Row className="mt-2 align-items-center">
            {values.idStaff !== "" && (
              <Col>
                <TextInput
                  label="Empleado"
                  inputType="text"
                  inputName="staffFullName"
                  value={values.staffFullName}
                  isDisabled
                />
              </Col>
            )}
            <Col xs={12} md={12} lg={4}>
              <Button color="info" onClick={() => setModalTabla(true)}>
                Buscar Empleado
              </Button>
            </Col>
          </Row>

          <div className="row mt-3 justify-content-end">
            <div className="order-sm-1 text-center text-sm-right mb-4">
              <Button color="success" type="submit">
                Guardar
              </Button>
            </div>
          </div>

          <ModalTableVer
            modal={modalTabla}
            setModal={setModalTabla}
            title="Empleados"
            cabeceras={cabecerasModal}
            filtro={filtroModal}
            parametros={parametrosModal}
            selectRegistro={selectStaff}
            btnClose
          />

          <ModalTableVer
            modal={modalTabla}
            setModal={setModalTabla}
            title="Empleados"
            cabeceras={cabecerasModal}
            filtro={filtroModal}
            parametros={parametrosModal}
            selectRegistro={selectStaff}
            btnClose
          />
      </form>

      <FullFormLoader show={isLoading} message="Realizando cálculo..." />

      {resErrors && (
        <div>
          <div className="text-center mb-3">
            <h3>Errores encontrados durante el cálculo</h3>
          </div>
          <Row>
            <Datatable
              headers={["Numero de empleado", "Nombre", "Error"]}
              columns={[ { data: "staffNumber" }, { data: "name", render: (d) => <div style={{minWidth: "350px"}}>{d}</div> }, { data: "message" } ]}
              columnDefs={[
                { orderable: true, targets: [0], className: "text-center" },
              ]}
              data={resErrors.data}
              control="front"
            />
          </Row>
        </div>
      )}
    </div>
  );
};

export default FormCalculo;
